import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client"
import {setContext} from "@apollo/client/link/context"
import {onError} from "@apollo/client/link/error"

const httpLink = createHttpLink({
  uri: process.env.GATSBY_GRAPHQL_URI,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ` + token.replace(/"/g, "") : "",
    },
  }
})
const logoutLink = onError(({ graphQLErrors }) => {
  graphQLErrors.forEach(error => {
    if (error.extensions.code === "UNAUTHENTICATED") {
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      window.location.reload()
    }
  })
})
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: logoutLink.concat(authLink.concat(httpLink)),
})

export default client
