import React from "react"
import { ConfigProvider } from "antd"
import { Provider } from "jotai"
import "moment/locale/fr"
import frFR from "antd/es/locale/fr_FR"

export const wrapRootElement = ({ element }) => (
  <div style={{ backgroundColor: "rgb(245,245,245)", minHeight: "100vh" }}>
    <ConfigProvider componentSize="large" locale={frFR}>
      <Provider>{element}</Provider>
    </ConfigProvider>
  </div>
)
